exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktuelles-tsx": () => import("./../../../src/pages/aktuelles.tsx" /* webpackChunkName: "component---src-pages-aktuelles-tsx" */),
  "component---src-pages-ausbildungskonzept-tsx": () => import("./../../../src/pages/ausbildungskonzept.tsx" /* webpackChunkName: "component---src-pages-ausbildungskonzept-tsx" */),
  "component---src-pages-berufliche-ausbildung-tsx": () => import("./../../../src/pages/berufliche-ausbildung.tsx" /* webpackChunkName: "component---src-pages-berufliche-ausbildung-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-duales-studium-tsx": () => import("./../../../src/pages/duales-studium.tsx" /* webpackChunkName: "component---src-pages-duales-studium-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-wer-wir-sind-tsx": () => import("./../../../src/pages/wer-wir-sind.tsx" /* webpackChunkName: "component---src-pages-wer-wir-sind-tsx" */)
}

